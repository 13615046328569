import React, { useState, useCallback, useContext, useMemo } from 'react'
import './traductions.min.css'
const traductions = require('./traductions.json')

// traductions.sort( function( a, b ) {
//     a = a.label.toLowerCase();
//     b = b.label.toLowerCase();

//     return a < b ? -1 : a > b ? 1 : 0;
// });

export const LanguageContext = React.createContext({ language: 'fr', onChangeLanguage: (event) => { } })

function BtnLanguage() {
    const { language, onChangeLanguage } = useContext(LanguageContext)

    return <div className="switcher">
        <label className="switch" htmlFor="checkbox">
            <input type="checkbox" id="checkbox" onChange={onChangeLanguage} checked={language === 'fr'}></input>
            <div className="slider round"></div>
        </label>
    </div>
}

export function Toolbar() {
    return <div>
        <BtnLanguage></BtnLanguage>
    </div>
}

export function LabelTraduction({ label }) {
    const { language } = useContext(LanguageContext)
    const text = traductions.filter(t => { return t.label === label })[0]
    const textTrad = typeof (text) === 'undefined' ? label : language === 'fr' ? text.fr : text.en
    return <React.Fragment>{textTrad}</React.Fragment>
}

export function BodyTrad({ children }) {
    const [language, setLanguage] = useState('fr')

    const handleChangeLanguage = useCallback((event) => {
        setLanguage(l => l === 'fr' ? 'en' : 'fr');

        const HTMLLibelle = document.querySelectorAll('.libelle');
        
        HTMLLibelle.forEach(elem => elem.classList.add('fade-enter'));
        setTimeout(function(){
            HTMLLibelle.forEach(elem => elem.classList.remove('fade-enter'))
        },500);
    }, [])

    const value = useMemo(() => {
        return {
            language: language,
            onChangeLanguage: handleChangeLanguage
        }
    }, [handleChangeLanguage, language])

    return <LanguageContext.Provider value={value}>
        {children}
    </LanguageContext.Provider>
}
