import React from 'react'
import Home from './Home'
import { Route, Switch } from 'react-router-dom'
import Project from './project/Project'
import { LabelTraduction } from './component/traductions.jsx'

function App() {
    return (
        <div>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/enaos" component={() => <Project
                    title="ENAOS"
                    description="XEnaosDescription"
                    img1="enaos_accueil"
                    img2="enaos_business"
                    img3="enaos_visiteurs"
                    img4="enaos_coffre"
                    img5="enaos_sites"
                    competences={[
                        "Ajax",
                        "API STRIPE",
                        "API UDIANAS",
                        "ASP.NET",
                        "HTML",
                        "IIS",
                        "Javascript",
                        "Jquery",
                        "LESS",
                        "powershell",
                        "SSDT",
                        "SSMS",
                        "VB",
                        "Webservices",
                        "XSLT",
                        "XSLT:FO",
                        "XML",
                        "JSON"]}
                    missions={[{ label: "XTacheAuto" },
                    { label: "XCahierDesCharges" },
                    { label: "XFactureGestion" },
                    {
                        label: "XTacheSites", list: [
                            { label: "XSiteBusiness", link: "https://business.enaos.net/presentation.aspx" },
                            { label: "XSiteVisiteur", link: "https://www.enaos.net/" },
                            { label: "XSitePartenaire" },
                            { label: "XSiteCommerciaux" },
                            { label: "XSiteFabricant" },
                            { label: "XSiteAgents" },
                            { label: "XSiteFamille" },
                            { label: "XSiteRelationClient" },
                            { label: "XSiteAdmin" },
                        ]
                    },
                    { label: "XTravailEquipe" },
                    { label: "XEquipeGestion" },
                    { label: "XAPIStripe", link: "https://stripe.com/fr" },
                    { label: "XAPITreeNation", link: "https://tree-nation.com/" },
                    { label: "XReflexion" },
                    { label: "XAccesProd" },
                    { label: "XAideSSMSIndex" },
                    { label: "XLanguesGestion" },
                    { label: "XGestionParc" },
                    { label: "XWebserviceCreation" }]} />} />
                <Route path="/gofi" component={() => <Project
                    title="GOFI"
                    description="XGofiDescription"
                    img1="gofi_accueil"
                    img2="ofi_carte"
                    img3="ofi_espace"
                    img4="gofi_histoire"
                    img5="gofi_actualites"
                    competences={[
                        "Ajax",
                        "API",
                        "ASP.NET",
                        "HTML",
                        "IIS",
                        "Javascript",
                        "JQuery",
                        "LESS",
                        "SSDT",
                        "SSMS",
                        "VB",
                        "WebServices",
                        "XSLT",
                        "XSLT:FO"]}
                    missions={[{ label: "XTacheAuto" },
                    { label: "XCahierDesCharges" },
                    { label: "XConceptionSiteVisiteur", link: "https://www.gofi.fr/" },
                    { label: "XConceptionSiteMembre" },
                    { label: "XConceptionSiteAdmin" },
                    { label: "XFactureGestion" },
                    { label: "XAvanceeRelation" }]} />} />
                <Route path="/NotreBonTerroir" component={() => <Project
                    title="NotreBonTerroir"
                    description="XLFDTDescription"
                    img1="nbt_accueil"
                    img2="nbt_enregistrement"
                    img3="nbt_produits"
                    img4="nbt_choix"
                    img5="nbt_liste"
                    competences={[
                        "Ajax",
                        "DOCKER",
                        "Doctrine",
                        "HTML",
                        "Javascript",
                        "Jquery",
                        "JSON",
                        "Symfony 2.0",
                        "Twig",
                        "XML"]}
                    missions={[{ label: "XCreationAZ", link: "https://notrebonterroir.fr"},
                    { label: "XConceptionSiteAdmin" },
                    { label: "XGestionComptes"},
                    { label: "XHebergement" }]} />} />
                <Route path="/randomloto" component={() => <Project
                    title="RandomLoto"
                    description="XRDDescription"
                    img1="rl_accueil"
                    img2="rl_code"
                    img3="rl_tirage"
                    img4="rl_mongo"
                    img5="rl_csv"
                    competences={[
                        "DOCKER",
                        "HTML",
                        "Javascript",
                        "Jquery",
                        "MongoDB",
                        "NodeJS"]}
                    missions={[{ label: "XCreationAZ"},
                    { label: "XConceptionSiteAdmin" },
                    { label: "XHebergementlocal" }]} />} />
            </Switch>
            <footer className="bg-primary text-white footer-link">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <h4 className="libelle"><LabelTraduction label="XRetrouveMoi" /></h4>
                        </div>
                        <div className="col-3 col-sm-2 logo"><a href="https://www.facebook.com/TStephane" target="_blank" rel="noopener noreferrer"><span className="logofb"></span></a></div>
                        <div className="col-3 col-sm-2 logo"><a href="https://www.linkedin.com/in/stephane-toffoletti" target="_blank" rel="noopener noreferrer"><span className="logolink"></span></a></div>
                        <div className="col-3 col-sm-2 logo"><a href="https://gitlab.com/Zellack" target="_blank" rel="noopener noreferrer"><span className="logogitlab"></span></a></div>
                        <div className="col-3 col-sm-2 logo"><a href="https://github.com/Stephane08Git" target="_blank" rel="noopener noreferrer"><span className="logogit"></span></a></div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default App;
