import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { LabelTraduction, Toolbar } from './component/traductions.jsx'

class Home extends Component {

    constructor(props) {
        super(props);
        this.aJSFileName = [
            {
                name: 'bs-init',
                defer: true
            },
            {
                name: 'jquery.easing',
                defer: true
            },
            {
                name: 'creative',
                defer: true
            },
            {
                name: 'es2015/circle_resizer',
                defer: false
            }]
        this.JSElement = []
    }

    componentWillUnmount() {
        this.JSElement.forEach(Element => {
            Element.parentNode.removeChild(Element)
        })
        this.JSElement.splice(0, this.JSElement)
    }

    componentDidMount() {
        this.aJSFileName.forEach(JSFile => {
            let script = document.createElement("script")

            script.src = process.env.PUBLIC_URL + '/js/' + JSFile.name + '.js'
            script.defer = true

            document.body.appendChild(script)
            this.JSElement.push(script)
        })

        //document.body.appendChild(<script src={ process.env.PUBLIC_URL + '/js/aos.js'} defer></script>)
        // <script src={ process.env.PUBLIC_URL + '/js/jquery.easing.js'} defer></script>
        // <script src={ process.env.PUBLIC_URL + '/js/jquery.magnific-popup.js'} defer></script>
        // <script src={ process.env.PUBLIC_URL + '/js/creative.js'} defer></script>
        // <script src={ process.env.PUBLIC_URL + '/js/es2015/circle_resizer.js'} defer></script>

        // const script = document.createElement("script");

        // script.src = "https://use.typekit.net/foobar.js";
        // script.async = true;

        // document.body.appendChild(script);
    }

    render() {
        return (
            <div className="fade-enter">
                <nav className="navbar navbar-light navbar-expand-lg fixed-top" id="mainNav">
                    <div className="container position-relative"><a className="navbar-brand js-scroll-trigger" href="#page-top"><span className="libelle"><LabelTraduction label="XAccueil" /></span></a>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="nav navbar-nav ml-auto mr-60">
                                <li className="nav-item" role="presentation"><a className="nav-link js-scroll-trigger libelle"
                                    href="#about"><LabelTraduction label="XCompetences" /></a></li>
                                <li className="nav-item" role="presentation"><a className="nav-link js-scroll-trigger libelle"
                                    href="#services"><LabelTraduction label="XServices" /></a></li>
                                <li className="nav-item" role="presentation"><a className="nav-link js-scroll-trigger libelle"
                                    href="#portfolio">Portfolio</a></li>
                                <li className="nav-item" role="presentation"><a className="nav-link js-scroll-trigger libelle" href="#CV">CV</a>
                                </li>
                                <li className="nav-item" role="presentation"><a className="nav-link js-scroll-trigger libelle"
                                    href="#contact">Contact</a></li>
                            </ul>
                        </div>
                        <div className="navbar-traduction navbar-brand"><Toolbar /><button
                            data-toggle="collapse" data-target="#navbarResponsive" className="navbar-toggler navbar-toggler-right ml-5px"
                            type="button" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><i
                                className="fa fa-align-justify"></i></button></div>
                    </div>
                </nav>
                <header className="text-center text-white container-fluid p-header">
                        <div>
                            <div className="row no-gutters">
                                <div className="col-lg-12 mx-auto portfolio-title">
                                    <h3 className="libelle"><LabelTraduction label="XBienvenue" /></h3>
                                    <h1 className="text-uppercase libelle"><strong>portfolio</strong></h1>
                                    <hr className="mx-auto" />
                                </div>
                            </div>
                            <div>
                                <div className="full-width">
                                    <div className="col-lg-6 mx-auto">
                                        <p className="mb-5 libelle">“<LabelTraduction label="XSteveJob" />”</p>
                                        <p className="mb-5 text-right libelle">Steve Jobs</p>
                                    </div>
                                </div>
                                <a className="btn btn-primary btn-xl js-scroll-trigger libelle" role="button" href="#about"><LabelTraduction label="XMesCompetences" /></a>
                            </div>
                        </div>
                </header>
                <section id="about" className="bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col offset-lg-8 mx-auto text-center">
                                <h2 className="text-white section-heading libelle"><LabelTraduction label="XChampCompetences" /></h2>
                                <hr className="light mx-auto" />
                                <p className="text-faded mb-4 libelle"><LabelTraduction label="XChampCompetencesDescription" /></p><a className="btn btn-light btn-xl js-scroll-trigger libelle" role="button" href="#services"><LabelTraduction label="XMonProfil" /></a>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="competences" className="Competences-background">
                    <div className="container-fluid p-bt-0">
                        <div className="IconsContainer">
                            <div className="Icon SymfonyHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="55" data-wh="180" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="55" data-wh="180" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>Symfony</span>
                            </div>
                            <div className="Icon ReactJSHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="40" data-wh="200" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="40" data-wh="200" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>ReactJS</span>
                            </div>
                            <div className="Icon DockerHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="40" data-wh="230" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="40" data-wh="230" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>Docker</span>
                            </div>
                            <div className="Icon NodeJSHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="50" data-wh="120" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="50" data-wh="120" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>NodeJS</span>
                            </div>
                            <div className="Icon NodeJS">
                                <div className="circle-score"><svg>
                                    <circle data-percent="50" data-wh="120" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="50" data-wh="120" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>NodeJS</span>
                            </div>
                            <div className="Icon Docker">
                                <div className="circle-score"><svg>
                                    <circle data-percent="40" data-wh="230" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="40" data-wh="230" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>Docker</span>
                            </div>
                            <div className="Icon ReactJS">
                                <div className="circle-score"><svg>
                                    <circle data-percent="40" data-wh="200" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="40" data-wh="200" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>ReactJS</span>
                            </div>
                            <div className="Icon Symfony">
                                <div className="circle-score"><svg>
                                    <circle data-percent="55" data-wh="180" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="55" data-wh="180" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>Symfony</span>
                            </div>
                        </div>
                        <div className="IconsContainer">
                            <div className="Icon PHPHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="60" data-wh="152" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="60" data-wh="152" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>PHP</span>
                            </div>
                            <div className="Icon TypescriptHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="30" data-wh="102" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="30" data-wh="102" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>Typescript</span>
                            </div>
                            <div className="Icon HTMLHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="90" data-wh="200" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="90" data-wh="200" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>HTML</span>
                            </div>
                            <div className="Icon JavaScriptHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="70" data-wh="132" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="70" data-wh="132" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>JavaScript</span>
                            </div>
                            <div className="Icon JavaScript">
                                <div className="circle-score"><svg>
                                    <circle data-percent="70" data-wh="132" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="70" data-wh="132" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>JavaScript</span>
                            </div>
                            <div className="Icon HTML">
                                <div className="circle-score"><svg>
                                    <circle data-percent="90" data-wh="200" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="90" data-wh="200" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>HTML</span>
                            </div>
                            <div className="Icon Typescript">
                                <div className="circle-score"><svg>
                                    <circle data-percent="30" data-wh="102" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="30" data-wh="102" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>Typescript</span>
                            </div>
                            <div className="Icon PHP">
                                <div className="circle-score"><svg>
                                    <circle data-percent="60" data-wh="152" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="60" data-wh="152" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>PHP</span>
                            </div>
                        </div>
                        <div className="IconsContainer">
                            <div className="Icon VBAHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="60" data-wh="82" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="60" data-wh="82" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>VBA</span>
                            </div>
                            <div className="Icon VBHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="80" data-wh="132" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="80" data-wh="132" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>VB</span>
                            </div>
                            <div className="Icon CSharpHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="50" data-wh="122" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="50" data-wh="122" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>CSharp</span>
                            </div>
                            <div className="Icon DotNetHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="60" data-wh="182" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="60" data-wh="182" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>DotNet</span>
                            </div>
                            <div className="Icon DotNet">
                                <div className="circle-score"><svg>
                                    <circle data-percent="60" data-wh="182" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="60" data-wh="182" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>DotNet</span>
                            </div>
                            <div className="Icon CSharp">
                                <div className="circle-score"><svg>
                                    <circle data-percent="50" data-wh="122" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="50" data-wh="122" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>CSharp</span>
                            </div>
                            <div className="Icon VB">
                                <div className="circle-score"><svg>
                                    <circle data-percent="80" data-wh="132" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="80" data-wh="132" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>VB</span>
                            </div>
                            <div className="Icon VBA">
                                <div className="circle-score"><svg>
                                    <circle data-percent="60" data-wh="82" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="60" data-wh="82" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>VBA</span>
                            </div>
                        </div>
                        <div className="IconsContainer">
                            <div className="Icon MongoDBHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="30" data-wh="162" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="30" data-wh="162" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>MongoDB</span>
                            </div>
                            <div className="Icon MySQLHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="70" data-wh="200" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="70" data-wh="200" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>MySQL</span>
                            </div>
                            <div className="Icon JSONHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="90" data-wh="104" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="90" data-wh="104" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>JSON</span>
                            </div>
                            <div className="Icon APIHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="70" data-wh="80" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="70" data-wh="80" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>API Restful</span>
                            </div>
                            <div className="Icon API">
                                <div className="circle-score"><svg>
                                    <circle data-percent="70" data-wh="80" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="70" data-wh="80" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>API Restful</span>
                            </div>
                            <div className="Icon JSON">
                                <div className="circle-score"><svg>
                                    <circle data-percent="90" data-wh="104" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="90" data-wh="104" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>JSON</span>
                            </div>
                            <div className="Icon MySQL">
                                <div className="circle-score"><svg>
                                    <circle data-percent="70" data-wh="200" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="70" data-wh="200" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>MySQL</span>
                            </div>
                            <div className="Icon MongoDB">
                                <div className="circle-score"><svg>
                                    <circle data-percent="30" data-wh="162" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="30" data-wh="162" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>MongoDB</span>
                            </div>
                        </div>
                        <div className="IconsContainer">
                            <div className="Icon JQueryHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="80" data-wh="172" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="80" data-wh="172" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>JQuery</span>
                            </div>
                            <div className="Icon LessHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="80" data-wh="82" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="80" data-wh="82" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>Less</span>
                            </div>
                            <div className="Icon CSSHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="85" data-wh="105" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="85" data-wh="105" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>CSS</span>
                            </div>
                            <div className="Icon AdobeHide">
                                <div className="circle-score"><svg>
                                    <circle data-percent="45" data-wh="80" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="45" data-wh="80" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>Adobe</span>
                            </div>
                            <div className="Icon Adobe">
                                <div className="circle-score"><svg>
                                    <circle data-percent="45" data-wh="80" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="45" data-wh="80" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>Adobe</span>
                            </div>
                            <div className="Icon CSS">
                                <div className="circle-score"><svg>
                                    <circle data-percent="85" data-wh="105" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="85" data-wh="105" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>CSS</span>
                            </div>
                            <div className="Icon Less">
                                <div className="circle-score"><svg>
                                    <circle data-percent="80" data-wh="82" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="80" data-wh="82" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>Less</span>
                            </div>
                            <div className="Icon JQuery">
                                <div className="circle-score"><svg>
                                    <circle data-percent="80" data-wh="172" className="fond" cx="50%" cy="50%"></circle>
                                    <circle data-percent="80" data-wh="172" className="score" cx="50%" cy="50%"></circle>
                                </svg></div><span>JQuery</span>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="services">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h2 className="section-heading libelle"><LabelTraduction label="XAVotreService" /></h2>
                                <hr className="mx-auto" />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-3 text-center">
                                <div className="mx-auto service-box mt-5"><i className="fa fa-diamond fa-4x text-primary mb-3 sr-icons"
                                    data-aos="zoom-in" data-aos-duration="200" data-aos-once="true"></i>
                                    <h3 className="mb-3 libelle">Maintenance</h3>
                                    <p className="text-muted mb-0 libelle"><LabelTraduction label="XMaintenanceSite" />
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 text-center">
                                <div className="mx-auto service-box mt-5"><i className="fa fa-paper-plane fa-4x text-primary mb-3 sr-icons"
                                    data-aos="zoom-in" data-aos-duration="200" data-aos-delay="200" data-aos-once="true"></i>
                                    <h3 className="mb-3 libelle"><LabelTraduction label="XDisponibilite" /></h3>
                                    <p className="text-muted mb-0 libelle"><LabelTraduction label="XDisponibleEcoute" /></p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 text-center">
                                <div className="mx-auto service-box mt-5"><i className="fa fa-newspaper-o fa-4x text-primary mb-3 sr-icons"
                                    data-aos="zoom-in" data-aos-duration="200" data-aos-delay="400" data-aos-once="true"></i>
                                    <h3 className="mb-3 libelle">Informations</h3>
                                    <p className="text-muted mb-0 libelle"><LabelTraduction label="XInformations" /></p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 text-center">
                                <div className="mx-auto service-box mt-5"><i className="fa fa-heart fa-4x text-primary mb-3 sr-icons"
                                    data-aos="fade" data-aos-duration="200" data-aos-delay="600" data-aos-once="true"></i>
                                    <h3 className="mb-3 libelle"><LabelTraduction label="XMetierPlus" /></h3>
                                    <p className="text-muted mb-0 libelle"><LabelTraduction label="XPation" /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="Profil" className="bg-dark text-white">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="libelle"><LabelTraduction label="XPresentation0" /></p>
                                <p className="libelle"><LabelTraduction label="XPresentation1" /></p>
                                <p className="libelle"><LabelTraduction label="XPresentation2" /></p>
                            </div>

                            <div className="col-6">
                                <img alt="Groot" src={process.env.PUBLIC_URL + '/img/Groot3.png'} className="w-100" />
                                <div className="row popup-gallery groot-gallery">
                                    <div className="col-4"><a className="portfolio-box" href={process.env.PUBLIC_URL + '/img/fullsize/Groot_dessin.png'}>
                                        <img alt="Groot" className="w-100" src={process.env.PUBLIC_URL + '/img/thumbnails/Groot_dessin.png'} />
                                    </a></div>
                                    <div className="col-4"><a className="portfolio-box" href={process.env.PUBLIC_URL + '/img/fullsize/Groot_dessin2.png'}>
                                        <img alt="Groot" className="w-100" src={process.env.PUBLIC_URL + '/img/thumbnails/Groot_dessin2.png'} />
                                    </a></div>
                                    <div className="col-4"><a className="portfolio-box" href={process.env.PUBLIC_URL + '/img/fullsize/Groot_dessin3.png'}>
                                        <img alt="Groot" className="w-100" src={process.env.PUBLIC_URL + '/img/thumbnails/Groot_dessin3.png'} />
                                    </a></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section id="portfolio" className="p-0">
                    <div className="container-fluid p-bt-0">
                        <div className="row">
                            <div className="col-sm-6 col-lg-4 pad-5"><Link className="portfolio-box img-block"
                                to="/enaos">
                                <div className="img-project Enaos"></div>
                                <div className="portfolio-box-caption">
                                    <div className="portfolio-box-caption-content">
                                        <div className="project-category text-faded"><span><LabelTraduction label="XProjet" /></span></div>
                                        <div className="project-name"><span>ENAOS</span></div>
                                    </div>
                                </div>
                            </Link></div>
                            <div className="col-sm-6 col-lg-4 pad-5"><Link className="portfolio-box img-block"
                                to="/gofi">
                                <div className="img-project GOFI"></div>
                                <div className="portfolio-box-caption">
                                    <div className="portfolio-box-caption-content">
                                        <div className="project-category text-faded"><span><LabelTraduction label="XProjet" /></span></div>
                                        <div className="project-name"><span>GOFI</span></div>
                                    </div>
                                </div>
                            </Link></div>
                            <div className="col-sm-6 col-lg-4 pad-5"><Link className="portfolio-box img-block"
                                to="/notrebonterroir">
                                <div className="img-project NBT"></div>
                                <div className="portfolio-box-caption">
                                    <div className="portfolio-box-caption-content">
                                        <div className="project-category text-faded"><span><LabelTraduction label="XProjet" /></span></div>
                                        <div className="project-name"><span>NotreBonTerroir</span></div>
                                    </div>
                                </div>
                            </Link></div>
                            <div className="col-sm-6 col-lg-4 pad-5"><Link className="portfolio-box img-block"
                                to="randomloto">
                                <div className="img-project RL"></div>
                                <div className="portfolio-box-caption">
                                    <div className="portfolio-box-caption-content">
                                        <div className="project-category text-faded"><span><LabelTraduction label="XProjet" /></span></div>
                                        <div className="project-name"><span>RandomLoto</span></div>
                                    </div>
                                </div>
                            </Link></div>
                        </div>
                    </div>
                </section>
                <section id="CV" className="bg-dark text-white">
                    <div className="container text-center">
                        <h2 className="mb-4 libelle"><LabelTraduction label="XMonCV" /></h2><a className="btn btn-light btn-xl sr-button libelle" data-aos="zoom-in"
                            data-aos-duration="400" data-aos-once="true" href={process.env.PUBLIC_URL + '/pdf/CV_TOFFOLETTI_Stephane_fr.pdf'} target="_blank" rel="noopener noreferrer"><LabelTraduction label="XTelechargeMoi" /></a>
                    </div>
                </section>
                <section id="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mx-auto text-center">
                                <h2 className="section-heading libelle"><LabelTraduction label="XContactMoi" /></h2>
                                <hr className="mx-auto" />
                                <p className="mb-5"></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 ml-auto text-center"><i className="fa fa-phone fa-3x mb-3 sr-contact"
                                data-aos="zoom-in" data-aos-duration="300" data-aos-once="true"></i>
                                <p><a href="tel:+33626034094">+33 (0)6 26 03 40 94</a></p>
                            </div>
                            <div className="col-lg-4 mr-auto text-center"><i className="fa fa-envelope-o fa-3x mb-3 sr-contact"
                                data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" data-aos-once="true"></i>
                                <p><a href="mailto:toffoletti-stephane@hotmail.fr">toffoletti-stephane@hotmail.fr</a></p>
                            </div>
                        </div>
                    </div>
                </section>
                <svg width="0" height="0" className="p-absolute">
                    <defs>
                        <linearGradient id="cl1" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0.7" y2="1"
                            gradientTransform="rotate(-15)">
                            <stop offset="0%" stopColor="#49FB52"></stop>
                            <stop offset="70%" stopColor="#F2ED3C"></stop>
                            <stop offset="100%" stopColor="#FF1E5A"></stop>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        );
    }
}

export default Home;