import React from 'react'
import { Link } from 'react-router-dom'
import { LabelTraduction, Toolbar } from '../component/traductions.jsx'
import './Project.min.css'

function MissionsListes(index, labeltrad, letter) {
    return (<li className="libelle text-break" key={letter + index}><LabelTraduction label={labeltrad.label} />
        {(typeof labeltrad.link !== 'undefined') && (<span>&nbsp;(<a href={labeltrad.link} target="_blank" rel="noopener noreferrer">{labeltrad.link}</a>)</span>)}
    </li>)
}

const Project = function Project({
    title,
    description,
    img1,
    img2,
    img3,
    img4,
    img5,
    competences,
    missions
}) {
    return (
        <div className="fade-enter">
            <nav className="navbar navbar-light navbar-expand-lg fixed-top" id="mainNav">
                <div className="container"><Link className="navbar-brand js-scroll-trigger libelle" to="/"><LabelTraduction label="XAccueil" /></Link>
                    <div>
                        <ul className="nav navbar-nav ml-auto">
                            <li><Toolbar /></li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div>
                <header className="text-center text-white d-flex p-0">
                    <div className="description w-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mx-auto">
                                    <h1 className="mt-100">{title}</h1>
                                    <hr className="mx-auto" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mx-auto">
                                    <h3 className="libelle"><LabelTraduction label="XDescription" /></h3>
                                </div>
                            </div>
                            <div className="row mb-20">
                                <div className="col-lg-12 mx-auto"><p className="libelle">
                                    <LabelTraduction label={description} />
                                </p></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mx-auto mt-20; mb-20 w-100">
                                    <div className="accordian">
                                        <ul>
                                            <li>
                                                <div className={img1}></div>
                                            </li>
                                            <li>
                                                <div className={img2}></div>
                                            </li>
                                            <li>
                                                <div className={img3}></div>
                                            </li>
                                            <li>
                                                <div className={img4}></div>
                                            </li>
                                            <li>
                                                <div className={img5}></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mx-auto">
                                    <h3 className="libelle"><LabelTraduction label="XMissions" /></h3>
                                    <hr className="mx-auto" />
                                </div>
                            </div>
                            <div className="row">
                                <ul className="mx-auto text-left w-100 form-check">
                                    {missions.map((labeltrad, index) => {
                                        return <React.Fragment key={index}> {MissionsListes(index, labeltrad, 'M')}
                                            {(typeof labeltrad.list !== 'undefined') && <ul className="ml-40">{labeltrad.list.map((item, i) => MissionsListes(i, item, 'L'))}</ul>}
                                        </React.Fragment>
                                    })}
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mx-auto">
                                    <h3 className="libelle"><LabelTraduction label="XMesCompetences" /></h3>
                                    <hr className="mx-auto" />
                                </div>
                            </div>
                            <div className="container">
                                <div className="row Competences">
                                    {competences.map((element, index) => <div className="col-lg-2" key={index}>{element}</div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    );
}

export default Project;